<template>
  <div class="ai-data">
    <div class="block" :class="index == 4 ? 'total' : ''" v-for="(item, index) in list" :key="index">
      <!-- {{ index }}
       -->
      <!-- <div class="block-" -->
      <div class="total-block" v-if="index == 4">
        <div class="rmb">
          <div class="label">今日成交订单金额（元）</div>
          <div class="value">
            <!-- {{ formatMoney(item.total_amount || 0) }} -->
            <countTo :startVal="item.start_val" :endVal="item.end_val" :duration="10000"></countTo>
          </div>
        </div>
        <div class="stats">
          <div class="stat">
            <div class="label">今日成交订单</div>
            <div class="value">{{ formatMoney(item.total_order || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">今日累计看播量</div>
            <div class="value">{{ formatMoney(item.total_online || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">今日累计直播时长</div>
            <div class="value">{{ formatMoney(item.live_time || 0) }}h</div>
          </div>

        </div>
      </div>
      <div class="data-block" v-else>
        <div class="user">
          <img :src="item.avatar" class="avatar">
          <div class="name">{{ item.acc_name }}</div>
          <div class="living">{{ item.is_live == 1 ? '直播中' : '' }}</div>
        </div>
        <div class="stats">
          <div class="stat">
            <div class="label">今日成交GMV</div>
            <div class="value">{{ formatMoney(item.today_order_amount || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">今日在线人数</div>
            <div class="value">{{ formatMoney(item.today_online || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">累计直播时长</div>
            <div class="value">{{ formatMoney(item.live_time || 0) }}h</div>
          </div>

          <div class="stat">
            <div class="label">累计在线人数</div>
            <div class="value">{{ formatMoney(item.live_num || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">累计成交GMV</div>
            <div class="value">{{ formatMoney(item.total_order_amount || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">累计成交订单</div>
            <div class="value">{{ formatMoney(item.order_num || 0) }}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import { Toast } from "vant";

// Vue.use(Toast);
// Vue.use(Button);

import countTo from 'vue-count-to';

export default {
  components: { countTo },
  name: "ai-data",
  data() {
    return {
      total: {},
      list: []
    };
  },
  created() {
    document.title = "AI智播 - 数据大屏展示";

    // setInterval(() => {
    //   this.loadData();
    // }, (Math.random() * 10 + 20) * 1000 );
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatMoney(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      return formatter.format(value).replace('$', '').replace('.00', '');
      // return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    },
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    loadData() {
      // Toast.loading({
      //   message: '加载中...',
      //   forbidClick: false,
      // });

      // this.list = [];

      this.$get('app/ai_data', null, (res) => {
        // console.log(res);
        // Toast.clear();
        if (res.code == 0) {

          const temp = []
          // if (this.total)

          const start = this.total.total_amount || 0;

          this.total = res.data.total || {};

          this.total.start_val = Number(start);
          this.total.end_val = Number(this.total.total_amount || 0);

          // this.list = res.data.list || [];
          const arr = res.data.list || [];
          for (let i = 0; i < 8; i++) {
            temp.push(arr[i]);
          }

          temp.splice(4, 0, this.total);

          this.list = temp;

          // console.log(this.list);

          const times = this.randomIntFromInterval(5, 20);
          // console.log(times);

          setTimeout(() => {
            this.loadData();
          }, times * 1000);
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped>
.ai-data {
  width: 100vw;
  height: 100vh;

  background-color: #000;
  display: grid;
  grid-template-columns: repeat(3, calc((100vw - 10px) / 3));
  grid-template-rows: repeat(3, calc((100vh - 10px) / 3));
  gap: 5px;
}

.block {
  // margin: 15px;
  background-color: rgb(241, 83, 118);
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;

  display: flex;
  align-items: center;

  font-family: 'PingFang SC';

  &.total {
    background-color: rgb(14,24,43);
  }
}

.total-block {
  // background-color: rgba($color: #000000, $alpha: .8);
  width: 100%;

  .rmb {
  text-align: center;
  margin-bottom: 3rem;

  .label {
    font-size: 1.2rem;
    color: #fff;

  }

  .value {
    font-size: 4rem;
    color: #fff;
    margin-top: 10px;
    font-family: 'PingFang SC';
    font-weight: bold;
  }
}

  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 1rem;

    .value {
      font-size: 2rem;
      margin-top: 5px;
      font-weight: bold;
    }

    .stat {
      text-align: center;
    }
  }
}

.data-block {
  // background-color: rgba(0, 0, 0, 0.73);

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .avatar {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }

    .name {
      font-size: 1.3rem;
      color: #fff;
      font-weight: bold;
      flex: 1;
      margin-left: 10px;
    }

    .living {
      color: #fff;
      font-size: 0.8rem;
    }
  }

  .stats {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    color: #fff;
    font-size: 1rem;
    flex-wrap: wrap;

    .stat {
      // margin: 0 15px 15px 0;
      margin-bottom: 2rem;
      margin-right: 15px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:nth-child(4) {
        margin-bottom: 0;
      }

      &:nth-child(5) {
        margin-bottom: 0;
      }

      &:nth-child(6) {
        margin-bottom: 0;
      }

      width: calc((100% - 30px) / 3);
    }

    .value {
      font-size: 1.8rem;
      margin-top: 5px;
      font-weight: bold;
      // font-size: 14px;
    }

    .stat {
      text-align: center;
    }
  }
}

.head {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
}
</style>